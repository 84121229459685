import React from 'react';
import './App.css'; // You can create Tokenomics.css for styling
import './App.js';
const Tokenomics = () => {
  return (

    <div>
    <section id="tokenomics">


<div class="container">
<div class="fn_cs_title">
            <div class="divider" data-color="2">
              <div class="line"></div>
              
            </div>
    <h3 class="title" data-color="white">Tokenomics</h3>
  </div>
</div>


<div class="container">
  <div class="fn_row">
    
    <div class="fn_col2">
    <div className="details-column">
    <h2>Token Details</h2>
        <table>
          <tbody>
            <tr>
              <td>Token Name:</td>
              <td>BaseToken</td>
            </tr>
            <tr>
              <td>Symbol:</td>
              <td>BASE</td>
            </tr>
            <tr>
              <td>Total Supply:</td>
              <td>1 billion BaseTokens</td>
            </tr>
            <tr>
              <td>Blockchain:</td>
              <td>Solana</td>
            </tr>
            <tr>
              <td>Presale Price:</td>
              <td>1 SOL = 100,000 Base</td>
            </tr>
            <tr>
              <td>Presale duration:</td>
              <td>7 Days only</td>
            </tr>
            <tr>
              <td>Token Address:</td>
              <td>i8ujghdndydddddd8xjjasjskkw33</td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>

    <div class="fn_col22">
        <div className="details-column">
      <p class="fn_animated_text small" data-color="white">
BaseToken is designed to be a unique cryptocurrency token that captures the essence of the BASE network, owned by Coinbase. Leveraging the widespread appeal of Megan Trainor's hit song "All About That Base," the project aims to generate significant buzz and attract investors. The BaseToken ecosystem will feature a dynamic website that integrates the song, providing an engaging user experience while highlighting the advantages of the BASE network. Through strategic marketing and a strong community focus, BaseToken intends to create a vibrant and active user base, driving adoption and value within the cryptocurrency market.
</p></div>
    </div>


  </div>
</div>




</section>
</div>



  );
};

export default Tokenomics;
