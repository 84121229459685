import React from 'react'

const About = () => {
  return (
    <div>
              <section id="about">
          
          
          <div class="container">
            <div class="fn_cs_title">
             
              <h3 class="title" data-color="black">About Us</h3>
              <div class="divider" data-color="1">
                <div class="line"></div>
               
              </div>
            </div>
          </div>
          
          
          <div class="container">
            <div class="fn_row">
            <div class="fn_col2">
               <div class="about_img">
            <img src="img/base2.png" alt=""/>
          </div>
              {/* <p class="fn_animated_text small" data-color="#fff">
               
</p> */}
              </div>
              <div class="fn_col2" style={{ display: 'grid', alignItems: 'center' }}>
                <p class="fn_animated_text small" data-color="#fff">We strive to develop "BaseToken," a distinctive cryptocurrency token that honors the BASE network, owned by Coinbase. The project will leverage the popularity of Megan Trainor's hit song "All About That Base" to generate buzz and encourage investment. The plan includes creating a vibrant website featuring the song and promoting the benefits of the BASE network.
                  <br/>
                  By integrating the excitement of Megan Trainor's "All About That Base" with the innovation of the BASE network, BaseToken aims to create a unique and engaging cryptocurrency project. This strategic approach leverages popular culture and robust technology to attract a broad audience and drive investment.
</p>
              </div>
            </div>
          </div>
          
          {/* <div class="about_img">
            <img src="img/about/CHICO_V1.png" alt=""/>
            
            
          </div> */}
          
          
        </section>
    </div>
  )
}

export default About
