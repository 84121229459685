import React from 'react'

const Roadmapnew = () => {
  return (
    <div>
             



<section class="timeline" id="roadmap">

<div class="container">
          <div class="fn_cs_title">
            <div class="divider" data-color="2">
              <div class="line"></div>
              
            </div>
            <h3 class="title" data-color="white">Roadmap</h3>
            {/* <p class="desc fn_animated_text">Embark on a journey from grassroots engagement to global dominance, as Family (FAM) charts a path from viral sensation to a 1 billion market cap. Witness our evolution through strategic expansions, innovative NFT launches, and community-driven milestones.</p>
            <p class="desc fn_animated_text">We will update the roadmap as we grow.</p> */}
          </div>
        </div>



<div class="roadmaps">
  <div class="point">
    <div class="point-index">1</div>
    <div class="point-label">Phase 1:</div>
            <div class="point-text">○ Preparation and Development <br/>
            ○ Finalize project concept and tokenomics.<br/>
              ○ Develop the BaseToken smart contract.<br />
              ○ Design and develop the website.<br />
              ○ Create the whitepaper and marketing materials.

    </div>
  </div>
  <div class="point">
    <div class="point-index">2</div>
    <div class="point-label">Phase 2</div>
    <div class="point-text"> ○ Pre-launch <br/>
    ○ Launch the website and social media channels.<br/>
    ○ Start marketing and promotional campaigns.<br />
    ○ Conduct the pre-sale event.

</div>
  </div>
  <div class="point">
    <div class="point-index">3</div>
    <div class="point-label">Phase 3</div>
        <div class="point-text"> ○ Official Launch <br/>
        ○ Conduct the ICO.<br/>
        ○ List Base on DEXs.<br />
        ○ Listing on Coingecko.<br/>
        ○ Listing on CoinMarket Cap<br/>
        ○ Listing on some other popular CEXs.
</div>
  </div>
  <div class="point">
    <div class="point-index">4</div>
    <div class="point-label">Phase 4</div>
    <div class="point-text">○ Expansion <br/>
    ○ Establish partnerships and integrations.<br/>
    ○ Continue marketing and community engagement.<br />
    ○ Expand use cases and merchant acceptance.</div>
  </div>
  {/* <div class="point">
    <div class="point-index">5</div>
    <div class="point-label">Label</div>
    <div class="point-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
  </div>
  <div class="point">
    <div class="point-index">6</div>
    <div class="point-label">Label</div>
    <div class="point-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
  </div> */}
</div>
  </section>








    </div>
  )
}

export default Roadmapnew
