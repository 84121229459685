import React from 'react'

const Header = () => {
  return (
    <div>
      <header id="header">
        <div class="header">
          <div class="header_in">
            <div class="logo">
              <a class="has_text" href="index-2.html">
                <img src="img/base.png" alt=""/>
              
              </a>
            </div>
            <div class="nav">
              <ul>
                <li class="menu-item-has-children">
                  <a class="gradient_link" href="#home"><span>Home</span></a>
                
                </li>
                <li><a class="gradient_link" href="#about"><span>About</span></a></li>
                <li><a class="gradient_link" href="#faq"><span>Faq</span></a></li>
                <li><a class="gradient_link" href="#roadmap"><span>Roadmap</span></a></li>
                </ul>
            </div>
            
          </div>
        </div>
      </header>
      
      <div class="metaverse_fn_mobnav">
        <div class="mob_top">
          <div class="logo">
            <a href="index-2.html">
              <img src="img/logo2.png" alt=""/>
            </a>
          </div>
          <div class="triggers">
          
           {/*
            <a href="#" class="connect_btn" target="_blank" rel="noreferrer"><span class="text">Telegram</span></a>
  */}<a class="trigger trigger_menu" href="#"><span></span></a>
            
          </div>
        </div>
        <div class="mob_bot">
          <ul>
            <li>
              <a class="creative_link" href="#home">Home</a>
            
            </li>
            <li><a class="creative_link" href="#about">About</a></li>
            <li><a class="creative_link" href="#roadmap">Roadmap</a></li>
            <li><a class="creative_link" href="#team">Team</a></li>
            
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header


