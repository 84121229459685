import React from 'react'

const Faq = () => {
  return (
    <div>
        <section id="faq">
        
          
        <div class="container">
          <div class="fn_cs_title">
            <div class="divider" data-color="1">
              <div class="line"></div>
              
            </div>
            <h3 class="title" data-color="black">FAQ</h3>
            {/* <p class="desc fn_animated_text" data-color="black">Find answers to your most pressing questions about Family (FAM) and uncover the essentials of our community-driven journey towards a 1 billion market cap. Dive into our FAQ to navigate the exciting features, opportunities, and roadmap of our project.</p> */}
          </div>
        </div>
        
        
        <div class="container">
          <div class="fn_cs_row">
            <div class="fn_col">
              <div class="fn_cs_accordion">
                <div class="acc_item active">
                  <div class="acc_header">
                    <h3 class="fn__maintitle">What is Base ?</h3>
                    <span class="icon"><span></span></span>
                  </div>
                  <div class="acc_content">
                    <p>We aim to create a unique cryptocurrency token, "BaseToken," that celebrates the BASE network, owned by Coinbase. The project will leverage the popularity of Megan Trainor's hit song "All About That Base" to generate buzz and encourage investment. The plan includes creating a vibrant website featuring the song and promoting the benefits of the BASE network.
                    </p>
                  </div>
                </div>
                <div class="acc_item">
                  <div class="acc_header">
                    <h3 class="fn__maintitle">What cryptocurrencies can I use to purchase BASE?
                    </h3>
                    <span class="icon"><span></span></span>
                  </div>
                  <div class="acc_content">
                    <p>
                    You can use ETH and BNBs 
                    </p>
                  </div>
                </div>
                <div class="acc_item">
                  <div class="acc_header">
                    <h3 class="fn__maintitle">How to buy BASE ?
                    </h3>
                    <span class="icon"><span></span></span>
                  </div>
                  <div class="acc_content">
                    <p>
                    Step1: Download metamask or trust wallet <br/>
                      Step2: Buy BNBs or ETHs from any exchange Like binance or coinbase<br/>
                      Step3: Send your BNBs to your metamask or trust wallet wallet.<br/>
                      Step4: Click Buy 2nd button<br/>
                      Step5: Enter desired No. of BASE you want to buy and click on BUY BASE button to connect your wallet <br/>
                      Step6: Confirm Transaction<br/>

                    2nd are in your wallet Now, Hold BASE to enjoy the ride </p>
                  </div>
                </div>
             
              </div>
            </div>
            <div class="fn_col">
              <div class="fn_cs_accordion active" >
                <div class="acc_item">
                  <div class="acc_header">
                    <h3 class="fn__maintitle">Where can i find more information about BASE?
                    </h3>
                    <span class="icon"><span></span></span>
                  </div>
                  <div class="acc_content">
                    <p>
                    Visit our official website <a href="basetoken.org">basetoken.org</a> and attached social Medias for detailed information, announcements, and upcoming events.

                    </p>
                  </div>
                </div>
                <div class="acc_item">
                  <div class="acc_header">
                    <h3 class="fn__maintitle">Where will it be listed ?
                    </h3>
                    <span class="icon"><span></span></span>
                  </div>
                  <div class="acc_content">
                    <p>After Presale it will be listed on BASE supported DEXs after that it will be listed on popular CEXs like then it will be listed on big CEXs exchanges like Binance and Coinbase.</p>
                  </div>
                </div>
              

               


               
              </div>
            </div>
          </div>
        </div>
          
        <div class="bottom_icon">
          
          
        </div>
        
      </section>
    </div>
  )
}

export default Faq
