import React from 'react';

const Goals = () => {
  return (
    <div className="goals-container">
      <section id="goals">
        <div className="goals-content">
          <div className="goals-title">
            <h3 className="goals-title-text" data-color="black">Goals</h3>
            <div className="goals-divider" data-color="1">
              <div className="goals-line"></div>
            </div>
          </div>
        </div>

        <div className="goals-content">
          <div className="goals-row">
            <div className="goals-column" style={{ display: 'grid', alignItems: 'center' }}>
              <p className="goals-text small" data-color="#fff">
                Launch a successful cryptocurrency token, BaseToken, on the BASE network.
              </p>
            </div>
            <div className="goals-column" style={{ display: 'grid', alignItems: 'center' }}>
              <p className="goals-text small" data-color="#fff">
                Create a strong association between BaseToken and the BASE network using Megan Trainor's song.
              </p>
            </div>
            <div className="goals-column" style={{ display: 'grid', alignItems: 'center' }}>
              <p className="goals-text small" data-color="#fff">
               BASE, owned by Coinbase, is the preferred network for launching BaseToken due to its robustness, security, and association with a leading cryptocurrency company.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Goals;
