import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Preloader from './Preloader';
import Herosection from './Herosection';
import About from './About';
import Goals from './Goals';
import Roadmapnew from './Roadmapnew';
import Faq from './Faq';
import Tokenomics from './tokenomics';
import Tokenallocation from './Tokenallocation';
function App() {

  return (
    <div>

    <Preloader/>
    
    
    <div class="metaverse_fn_main">
      
      <Header/>

      <div class="metaverse_fn_page">

        <Herosection/>
        <About/>
        <Goals/>
        <Tokenomics/>
        <Tokenallocation/>
        <Roadmapnew/>
        
        <Faq/>

        {/* <section id="parallax">
          <div class="myparallax" data-bg-img="img/parallax/6.png" data-parallax="yes"></div>
          <div class="particle_wrapper"><div id="particles-js"></div></div>
        </section> */}
        
      </div>
      
     <Footer/>

    </div>

    </div>
  );
}

export default App;
