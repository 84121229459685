import React from 'react';
import './App.css'; // You can create Tokenomics.css for styling
import './App.js';
const Tokenallocation = () => {
  return (

    <div>
    <section id="Tokenallocation">


<div class="container">
<div class="fn_cs_title">
            <div class="divider" data-color="2">
              <div class="line"></div>
              
            </div>
    
  </div>
</div>


<div class="container">
  <div class="fn_row">
    
    <div class="fn_col22">
        <div className="details-column">
        <iframe
        src="https://www.youtube.com/embed/7PCkvCPvDXk?si=8s_tngsJh2-d6GS_"
        style={{ border: '0px #ffffff none' }}
        name="myiFrame"
        scrolling="no"
        frameBorder="1"
        marginHeight="0px"
        marginWidth="0px"
        height="400px"
        width="600px"
        allowFullScreen
        title="YouTube Video"
      ></iframe>



</div>
    </div>
    <div class="fn_col2">
    <div className="details-column">
    <h2>Token Allocation</h2>
        <table>
          <tbody>
          <tr>
<td>Presale:</td>
<td>20%</td>
</tr>
<tr>
<td>Team:</td>
<td>10%</td>
</tr>
<tr>
<td>Liquidity:</td>
<td>50%</td>
</tr>
<tr>
<td>Marketing:</td>
<td>10%</td>
</tr>
<tr>
<td>Reserved:</td>
<td>10%</td>
</tr>
          </tbody>
        </table>
        </div>
    </div>



  </div>
</div>




</section>
</div>



  );
};

export default Tokenallocation;


