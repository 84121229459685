import React from 'react'

const Footer = () => {
  return (
    <div>
            <footer id="footer">
        <div class="container">
          <div class="footer">
            <div class="left_part">
              <p>Copyright 2024 — ALL ABOUT THAT BASE</p>
            </div>
            {/* <div class="right_part">
              
              <div class="social_icons">
                <ul>
                 
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      
      <a href="#" class="metaverse_fn_totop">
        <span class="icon"></span>
        <span class="arrow"></span>
        <span class="rocket"></span>
      </a>
    </div>
  )
}

export default Footer
