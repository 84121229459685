import React, { useState } from 'react'
import Countdown from './countdown';

const Herosection = () => {
    //  const UpdateTime = () => {
    //   let time = new Date().toLocaleTimeString();
    //   const [Currenttime, setCurrenttime] = useState(time);
    //    setCurrenttime(time);
    //  };
    //  setInterval(UpdateTime,1000);
    const targetDate = new Date('2024-07-04T00:00:00');
  return (
    <div>
              <section id="home">
          <div class="container">
            
            <div class="hero_header">
              <div class="hero_left">
                <div class="description">
                  <h3 class="title"> All About That Base</h3>
                  <p class="fn_animated_text">We aim to create a unique cryptocurrency token, "BaseToken," that celebrates the BASE network, owned by Coinbase.</p>
                  {/* <p class="fn_animated_text contract_address">Contract : <span>7njsg9BA1xvXX9DNpe5fERHK4zb7MbCHKZ6zsx5k3adr</span></p> */}
                </div>
              {/* <div class="footer top_socials">
                <div class="social_icons">
                  <ul>
                    <li><a href="https://twitter.com/familytokenoffl" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/twitter-1.svg" alt="Twitter" class="fn__svg"/></a></li>
                    <li><a href="https://t.me/FamilyTokenOfficial" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/telegram.svg" alt="Telegram" class="fn__svg"/></a></li>
                    <li><a href="https://discord.com/invite/familytoken" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/discord.svg" alt="Discord" class="fn__svg"/></a></li>
                    <li><a href="https://www.instagram.com/thefamilytoken/" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/instagram-1.svg" alt="Instagram" class="fn__svg"/></a></li>
                    <li><a href="https://www.tiktok.com/@familytoken" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/tiktok.svg" alt="Tiktok" class="fn__svg"/></a></li>
                    <li><a href="https://www.youtube.com/@TheFamilyToken/shorts" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/youtube.svg" alt="Youtube" class="fn__svg"/></a></li>
                    
                  </ul>
              </div>
              </div> */}
                  
                
                <div class="buttons">
                  <a href="#" class="metaverse_fn_button" target="_blank" rel="noreferrer">
                    <span class="text"  style={{color: "grey"}}>Buy $Base</span>
                  </a>
                  <a href="#" class="metaverse_fn_button white" target="_blank" rel="noreferrer">
                    <span class="text" style={{color: "grey"}}>Whitepaper</span>
                  </a>
                </div>
                
               
                <div class="helpfuls">
            <a href="#" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/twitter-1.svg" alt="Twitter" class="fn__svg"/></a>
            <a href="#" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/instagram-1.svg" alt="Instagram" class="fn__svg"/></a>
            <a href="#" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/tiktok.svg" alt="Tiktok" class="fn__svg"/></a>
            </div>




              </div>
              <div class="hero_right">
                <div class="fn_cs_glitch_slider">
                  <div class="slider">
                    <ul>
                      <li class="active">
                        <div class="item">
                          <div class="left__border"></div>
                          <div class="right__border"></div>
                          {/* <h1>{Currenttime}</h1> */}
                          <div>
                            <h4 class="centers">Unlock Your Fortune: Invest in Base</h4>
      <h1 class="center">Presale Starts in</h1>
      <Countdown targetDate={targetDate} />
    </div><br/>
    <div class="buttons1">
                  <a href="#" class="metaverse_fn_button" target="_blank" rel="noreferrer">
                    <span class="text">Hurry up to buy</span>
                  </a></div>
                          { /*<div class="image">
                            <img src="img/slider/CHICO_V2.png" alt=""/>
                            <div class="img">
                              <span data-bg-img="img/slider/CHICO_V2.png"></span>
                              <span data-bg-img="img/slider/CHICO_V2.png"></span>
                              <span data-bg-img="img/slider/CHICO_V2.png"></span>
                              <span data-bg-img="img/slider/CHICO_V2.png"></span>
                            </div>
                          </div>
            */ }
                          <div class="slider__count">
                            <div class="count-front">
                                <div class="count-overlay" data-index="Faceless Void"></div>
                            </div>
                            </div>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
         
        </section>
    </div>

    
  )
}

export default Herosection
